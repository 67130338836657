import STRINGS from '../../../common/strings/strings';
import Helper from '../../../apis/Helper';

export function validateUserFormData(data,fieldName) {
	let error = {};
	if(data.first_name === "")
		error.first_name = STRINGS.users.firstNameReq;
	if(data.last_name === "")
		error.last_name = STRINGS.users.lastNameReq;
	if(data.email === "")
		error.email = STRINGS.users.emailReq;
	if(data.location === "")
		error.location = STRINGS.users.locationReq;
	if(data.title === "")
		error.title = STRINGS.users.titleReq;
	if(data.description === "")
		error.description = STRINGS.users.descriptionReq;
	
	return error
}