import SweetAlert from 'react-bootstrap-sweetalert';

const Alerts = {
	
	confirmDelete: function(selected_id, callback){
		return(<SweetAlert
				warning
				showCancel
				confirmBtnText="Yes, delete it!"
				cancelBtnBsStyle="secondary"
				confirmBtnBsStyle="danger"
				title="Are you sure?"
				onConfirm={() => callback(selected_id)}
				onCancel={() => callback(0)}
				focusCancelBtn
				btnSize='sm'
				imageHeight='60px!important'
				imageWidth='60px!important'
				customClass='swal-style icon-class'
			>
				You will not be able to recover this data!
			</SweetAlert>);
	},
	
	showMessage: function (message) {
		return(<SweetAlert
				info
				focusCancelBtn
				title="This is required"
			>
			{message}
			</SweetAlert>);
	}
}

export default Alerts;