const appRoutes = {
    loginRoute : '/login',
    registerRoute : '/register',
    forgotPasswordRoute : '/forgot-password',
    resetPasswordRoute : '/reset-password',
    userListRoute : 'users-list',
    addEditUserRoute : 'users-add-edit',
	blogListRoute : 'blogs-list',
    addEditBlogRoute : 'blogs-add-edit',
    contactUsRoute : 'contact-us-list',
    dashboardRoute : 'dashboard',
    aboutUsRoute: '/about-us',
    faqRoute: '/faq',
    termsConditionRoute: '/terms-condition',
    privacyPolicyRoute: '/privacy-policy',
	authPrfixRoute: '/auth',   // It's important to add slash in front of auth to match routes 
}
export default appRoutes