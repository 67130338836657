import React, { useEffect, useState } from 'react'
import {useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import STRINGS from '../../common/strings/strings';
import { saveContactUs, getReviews } from '../../apis/DirectUsObject';
import { validateContactFormData } from './Validation/ContactUsValidation'


export default function Home() {
	const { formSubmit } = useSelector((state) => state.common);
	const [reviews, setReviews] = useState([]);
	const [contactDetail, setContactDetail] = useState({ name: '', email: '', message: '' });
	const [errorsInfo, setErrorsInfo] = useState({});
	const dispatch = useDispatch();
	const contactusref = useRef(null);
	
	useEffect(() => {
		getReviewList()
    }, []);
	
	const getReviewList = async () => {
		await getReviews()
				.then((res) => {
					//console.log('review list', res.data)
					setReviews(res.data)
				})
				.catch((error) => {
					
				});
	};

	const goToContactUsClick = () => {
		contactusref.current?.scrollIntoView({behavior: 'smooth'});
	};
	
	/** OnChange Update Input Values */
	const handleContactChange = (e) => {
		//console.log('after load handle change function called');
		setContactDetail({ ...contactDetail, [e.target.name]: e.target.value });
	}
	
	/** Validation implemented here */
	const checkFormIsValid = () => {
		let errors = validateContactFormData(contactDetail, '');
		console.log("errors", errors)
		if (Object.keys(errors).length > 0) {
			setErrorsInfo(errors);
			return false;
		}else{
			return true;
		}
	}

	/** Form Submit Functionality Here */
	const saveContactForm = async (e) => {
		e.preventDefault();
		if (checkFormIsValid()) {
			dispatch(callCommonAction({ formSubmit: true }));
			const res = await saveContactUs(contactDetail)
				.then((res) => {
					setContactDetail({ name: '', email: '', message: '' });
					dispatch(callCommonAction({ formSubmit: false }));
					
					toast.success(STRINGS.contactSuccess)
				})
				.catch((error) => {
					dispatch(callCommonAction({ formSubmit: false }));
					console.log('create contact catch error', error)
				});
		}
	}
	
   return (
      <div>
         <section className="banner_section">
            <div className="container">

               <div className="row">

                  <div className="col-left">
                     <div className="banner_inner">
                        <h1 className="heading_main">Making It Easy to Share Benefits Data with Your Vendors</h1>
                        <p className="main_text">Without any coding, CDX empowers you to implement, share, and monitor your HR, PayRoll, and Benefit data exchanges with your 3rd-party vendors</p>
                        <div className="banenr_btn">
                           <a href={undefined} className="transperent_btn get_btn btn">Get a Demo</a>
                           <a href={undefined} onClick={() => goToContactUsClick()} className="transperent_btn contact_btn btn">Contact</a>
                        </div>
                     </div>
                  </div>

                  <div className="col-right">
                     <div className="banner_number">
                        <div className="number_div">
                           <span className="number_style">89.011</span>
                        </div>

                        <div className="number_div style_2">
                           <span className="number_style">73.967</span>
                        </div>

                        <div className="number_div style_3">
                           <span className="number_style">43.456</span>
                        </div>

                     </div>
                  </div>


               </div>

               <div className="banner_light_house" style={{display: 'd-none'}}>
                  <img src="assets/images/light_house.png" />
               </div>


            </div>
         </section>

         <section className="takeback_section">
            <div className="container">

               <div className="row v_align">

                  <div className="col-7">

                     <div className="take_back_inner">
                        <h2 className="common_heading">Take Back Control of Your Benefits Data</h2>
                        <ul className="takeback_listing">

                           <li className="takeback_item">
                              <img src="assets/images/check_icon.svg" />
                              Data is buried in databases and programmed by IT team or expensive consultant
                           </li>

                           <li className="takeback_item">
                              <img src="assets/images/check_icon.svg" />
                              You have no visibility into status or issues with files
                           </li>

                           <li className="takeback_item">
                              <img src="assets/images/check_icon.svg" />
                              You have to rely on programmer to make any changes for you
                           </li>

                        </ul>

                        <div className="btn_area">
                           <a href={undefined} className="white_btn btn">Learn more about data visibility</a>
                        </div>
                     </div>

                  </div>

                  <div className="col-5">

                     <div className="take_back_inner_img">

                        <img src="assets/images/take_back_img.png" />

                     </div>

                  </div>

               </div>
            </div>
         </section>

         <section className="cloudbased_section">
            <div className="container">
               <div className="cloudbased_inner">
                  <h2 className="common_heading style_2">A cloud-based platform that’s put the <span className="font_weight_set">power of data and decision making back into the right hands.</span> The CDX platform gives HR and Benefits professionals the power to manage and share your HR and Benefits data with your vendors, without any coding. </h2>
                  <div className="cloudbased_btn">
                     <a href={undefined} className="lightblue_btn cdx_btn btn">CDX Platform</a>
                  </div>
               </div>
            </div>
         </section>

         <section className="known2u_section">
            <div className="container">
               <h2 className="common_heading">The Known2U Difference</h2>
               <p className="common_text style_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

               <div className="Known2U_table_area">
                  <ul className="Known2U_listing">

                     <li className="known2u_items">
                        <div className="Known2U_img">
                           <img src="assets/images/graph_ic.svg" />
                        </div>
                        <div className="Known2U_content">
                           <h3 className="heading_h3">Knowledge Transformed</h3>
                           <p className="para_new">Our platform makes your data and file transfers visible and known to you in real-time. This gives you the power to implement, manage, and monitor file transfers, without the help of IT. <a className="learn_link" href={undefined}>Learn more about data visibility.</a></p>
                        </div>
                     </li>

                     <li className="known2u_items">
                        <div className="Known2U_img">
                           <img src="assets/images/account_tree_ic.svg" />
                        </div>
                        <div className="Known2U_content">
                           <h3 className="heading_h3">Semantic Data Mapping</h3>
                           <p className="para_new">Through AI tools,our platform allows you to semantically map data between your systems, meaning you don’t need to understand code to create and send file transfer. <a className="learn_link" href={undefined}>Learn more about data mapping.</a></p>
                        </div>
                     </li>

                     <li className="known2u_items">
                        <div className="Known2U_img">
                           <img src="assets/images/app_ic.svg" />
                        </div>
                        <div className="Known2U_content">
                           <h3 className="heading_h3">All-in-One Platform</h3>
                           <p className="para_new">The CDX platform is a central location for your team to manage all of your data exchanges across all of your vendors. We offer 650+ pre-built integrations (and we’re always adding more!) <a className="learn_link" href={undefined}>Learn more about CDX.</a></p>
                        </div>
                     </li>

                  </ul>
               </div>
            </div>
         </section>

         <section className="how_itwork_section">
            <div className="container">
               <h2 className="common_heading style_2">How It Works</h2>
               <p className="common_text style_3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
               <div className="itwork_box">
                  <ul className="itwork_listing">

                     <li className="itwork_items">
                        <div className="img_area">
                           <img className="box_images" src="assets/images/settings_account_box.svg" />
                        </div>
                        <div className="itwork_content">
                           <h3 className="heading_h3 style_2">1. Set up Account</h3>
                           <p className="para_next">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                        </div>
                     </li>

                     <li className="itwork_items">
                        <div className="img_area">
                           <img className="box_images" src="assets/images/alt_route.svg" />
                        </div>
                        <div className="itwork_content">
                           <h3 className="heading_h3 style_2">2. Start Mapping</h3>
                           <p className="para_next">Build your Maps or have Known2U configure and implement for you</p>
                        </div>
                     </li>

                     <li className="itwork_items">
                        <div className="img_area">
                           <img className="box_images" src="assets/images/monitor_heart.svg" />
                        </div>
                        <div className="itwork_content">
                           <h3 className="heading_h3 style_2">3. Send & monitor files</h3>
                           <p className="para_next">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                        </div>
                     </li>

                  </ul>
               </div>
            </div>
         </section>
		{reviews.length ? 
			<section className="testimonial_section">
				<div className="container">
				   <h2 className="common_heading">Case studies – Take Control with Known2U</h2>
				   <div className="main_slider_area">
					  <div className="slider_area my-slider">
						{reviews.map((item) => 
							<div className="slider_content" key={ item.id }>
								<p className="para_next style_4">{ item.description }</p>
								<h4 className="testimonial_name">{ item.name }</h4>
								<h6 className="testimonial_title">{ item.company_name }</h6>
							</div>
							)
						}
					  </div>
				   </div>
				</div>
			</section>
		: ""
		}
         <section className="contactus_section">
            <div className="container">
               <h2 className="common_heading style_2">Contact us today to find out more about how Kown2U can help you with your Benefits data</h2>
               <p className="para_next style_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque <a href={undefined} className="learn_link">or give us a call.</a></p>

               <div ref={contactusref} className="form_area">

                  <form noValidate onSubmit={saveContactForm}>
                     <input type="text" id="name" name="name" value={contactDetail.name} onChange={handleContactChange} placeholder="Name" />
					 <span className='text-danger'>{errorsInfo.name}</span>
                     <input type="email" id="email" name="email" value={contactDetail.email} onChange={handleContactChange} placeholder="Email" />
					 <span className='text-danger'>{errorsInfo.email}</span>
                     <textarea type="text" id="message" name="message" value={contactDetail.message} onChange={handleContactChange} placeholder="Message"></textarea>
                     <span className='text-danger'>{errorsInfo.message}</span>
					 <div className="form_btn_area">
						{formSubmit ?
							<button type="button" className="lightblue_btn submit_btn btn">Sending <span className="spinner-border" role="status"></span></button>
							:
							<button type="submit" className="lightblue_btn submit_btn btn">Send Message</button>
						}
                     </div>
                  </form>
               </div>
            </div>
         </section>
      </div>
   )
}
