import React from 'react'

function AboutUs() {
   return (
      <div>
         <section className="banner-sec">
            <div className="banner-caption">
               <div className="hero-content">
            <div className="caption d-flex flex-column align-items-center position-relative">
              <div className="border-box"></div>
              <div className="gradient-box"></div>
              <h1>About Us</h1>
            </div>
          </div>
            </div>
         </section>

         <section className="inner-content">
            <div className="container">

               <div className='row flex-column flex-md-row align-items-center mb-4'>
                  <div className='col col-md-5 mb-3'>
                     <img src='assets/images/darksoul.png' alt='' title='' />
                  </div>
                  <div className='col col-md-7'>
                     <h4 className='text-white text-uppercase mb-3'>WHO WE ARE?</h4>
                     <p className='fs16'>
                        We at Wasai LLC respect the privacy of your personal and, as such, make every effort to ensure your information is protected and remains private. As the owner and  of loremipsum.io We at Wasai LLC respect the privacy of your personal and, as such, make every effort to ensure your information is protected and remains private. As the owner and  of loremipsum.io
                     </p>
                  </div>
               </div>


               <div className='row flex-column flex-md-row align-items-center mb-4 flex-md-row-reverse'>
                  <div className='col col-md-5 mb-3 text-md-end'>
                     <img src='assets/images/darksoul.png' alt='' title='' />
                  </div>
                  <div className='col col-md-7'>
                     <h4 className='text-white text-uppercase mb-3'>WHAT WE DO?</h4>
                     <p className='fs16'>
                        We at Wasai LLC respect the privacy of your personal information and, as such, make every effort to ensure your information is protected and remains private. As the owner and operator of loremipsum.io (the "Website") hereafter referred
                     </p>
                  </div>
               </div>

            </div>
         </section>
      </div>
   )
}

export default AboutUs;