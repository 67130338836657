import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useParams, useNavigate } from 'react-router-dom';
import appRoutes from '../../configs/AppRoutes';
import { CheckUnAuthorized } from '../Common/CheckUnAuthorized';
import { logout } from '../../apis/DirectUsObject';
const Dashboard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		//CheckUnAuthorized({error: {response: {statusText: "Unauthorized"}}, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
	});

	const logoutHandler = async () => {
		dispatch(callCommonAction({ loading: true }));
		await logout()
			.then(() => {
				localStorage.removeItem("token");
				localStorage.removeItem('user');
				dispatch(callCommonAction({ loading: false,isLoggedIn: false, token: null, user: null }));
				navigate(appRoutes.loginRoute);
			})
			.catch((error) => {
				CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
			})
	}

	return (
		<div className="content-wrapper">
			<div className="container">
				<div className="d-flex justify-content-between align-items-center breadcrum-container mb-3">
					<ol className="breadcrumb">
						<li className="breadcrumb-item active">Dashboard</li>
					</ol>
				</div>
				<div className="row">
					<div className="col-md-12 position-relative">
						<h1>Hello</h1>
						<div className="d-flex">
							<button className="btn btn-info m-2" type="link" onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.userListRoute)} > User Managment </button>
							<button className="btn btn-info m-2" type="link" onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.blogListRoute)} > Blog Managment </button>
							<button className="btn btn-info m-2" type="link" onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.contactUsRoute)} > Contact-Us Managment </button>
							<button className="btn btn-info m-2" type="link" onClick={logoutHandler} > Logout </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Dashboard;
