import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { blogList, deleteBlog,getListOfCategories } from '../../apis/DirectUsObject';
import { toast } from 'react-toastify';
import Alerts from '../../common/Alerts/Alerts';
import Pagination from '../Pagination/pagination';
import STRINGS from '../../common/strings/strings';
import { CheckUnAuthorized } from '../Common/CheckUnAuthorized';
import { useNavigate } from 'react-router-dom';
import { faTrashCan, faEye, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConfig from '../../configs/AppConfig';
import appRoutes from '../../configs/AppRoutes';
import { ContentLoading } from '../Common/ContentLoading';

export default function BlogList() {
   const { loading, alert } = useSelector((state) => state.common);
   const [dataListArray, setDataListArray] = useState([]);
   const [paginationData, setPaginationData] = useState({ perpage: AppConfig.recordsPerPageTable, page: 1, totalCount: 0 });
   const [filter, setFilter] = useState({ searchKeyword: '' });
	const [categoryList, setCategoryList] = useState([]);
   const navigate = useNavigate();
   const dispatch = useDispatch();

   useEffect(() => {
      getCategories();
      getBlogsList()
   }, [paginationData.page]);

   /**Get the lis of categories***/
	const getCategories = async () => {
		const res = await getListOfCategories();
		const categories = res.data.map((item) => {
			return { value: item.id, label: item.name }
		});
		setCategoryList(categories);
	}


   const getBlogsList = async () => {
      dispatch(callCommonAction({ loading: true }));
      await blogList(filter, paginationData.page).then(async (res) => {
         console.log('res', res)
         setDataListArray(res.data)
         setPaginationData({ ...paginationData, totalCount: res.meta.filter_count })
         //toast.success(STRINGS.blogListSuccess)
         dispatch(callCommonAction({ loading: false }));
      }).catch(error => {
         CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
      });
   }


   const confirmBeforeDelete = (selected_id) => {
      //console.log('selected_id', selected_id);
      const custom = Alerts.confirmDelete(selected_id, deleteSelectedUser);
      dispatch(callCommonAction({ alert: custom }));
   }

   //after confirmation delete data
   const deleteSelectedUser = async (selected_id) => {
      dispatch(callCommonAction({ alert: null }));
      if (selected_id) {
         dispatch(callCommonAction({ loading: true }));
         await deleteBlog(selected_id).then(async (res) => {
            toast.success(STRINGS.blogDeleteSuccess)
            await getBlogsList();
            dispatch(callCommonAction({ loading: false }));
         }).catch((error) => {
            CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
         });
      }
   };

   const prepareHtmlTable = () => {
      return dataListArray.map((item) => {
         const categoriesName = item.categories.map((pCat) => pCat.categories_id.name).join(', ');
         return (
            <tr v-for="item in tableItems" key={item.id}>
               <td className='image-fill'>
                  <img className="text-center" src={item.display_image !== null ? process.env.REACT_APP_CMS_URL_STAGING + '/assets/' + item.display_image : '/assets/images/profile-dummy.png'} width="35px" height="35px" />
               </td>
               <td>{item.title}</td>
               <td dangerouslySetInnerHTML={{ __html: item.description }} ></td>
               <td>{categoriesName}</td>
               <td>{item.tags ? item.tags.join() : 'No Tags'}</td>
               <td><span className={`badge ${item.status == 'published' ? 'bg-success' : 'bg-danger'}`}>{item.status}</span></td>
               <td>
                  <ul className="list-unstyled d-flex action-icons m-0">
                     {/*<li><button className="btn btn-sm" type="button" onClick={() => console.log('ss')} ><FontAwesomeIcon icon={faEye} /></button></li>*/}
                     <li><button className="btn btn-sm" type="button" onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.addEditBlogRoute + '/' + item.id)}><FontAwesomeIcon icon={faEdit} className='text-success' /></button></li>
                     <li><button className="btn btn-sm" type="button" onClick={() => confirmBeforeDelete(item.id)}><FontAwesomeIcon icon={faTrashCan} className='text-danger' /></button></li>
                  </ul>
               </td>
            </tr>
         )
      })
   }

   return (
      <div className="content-wrapper">
         <div className="container">
            <div className="d-flex justify-content-between align-items-center breadcrum-container mb-3">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <a href={undefined} onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.dashboardRoute)}>Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Blogs List</li>
               </ol>
               <div className="">
                  <button type="button" className="btn btn-info btn-sm rounded-pill" onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.addEditBlogRoute )}>
                     <FontAwesomeIcon icon={faPlus} /> Add Blog
                  </button>
               </div>
            </div>
            {alert}
            <div className="card card-custom shadow mb-3">
               <div className="card-header">
                  <div className="card-title">
                     <i className="fa fa-table"></i> Blogs List
                  </div>
                  <div className="card-toolbar">
                     <div className="d-flex align-items-center flex-wrap mb-0">
                        <div className="dash-search">
                           <form className="form-inline my-2 my-lg-0 mr-lg-2">
                              <div className="input-group">
                                 <input className="form-control border-end-0 border rounded-pill" onChange={(e) => setFilter({ ...filter, searchKeyword: e.target.value })} value={filter.searchKeyword} type="text" placeholder="Search for..." />
                                 <span className="input-group-append">
                                    <button className="btn border-0 rounded-pill ms-n5" type="button" onClick={() => getBlogsList()}><i className="fa fa-search text-black"></i></button>
                                 </span>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="card-body spinner-relative">
                  {loading ? <ContentLoading /> :
                     <div className="table-responsive">
                        <table className="table table-bordered" width="100%" cellSpacing="0">
                           <thead>
                              <tr>
                                 <th>Image</th>
                                 <th>Title</th>
                                 <th>Description</th>
                                 <th>Categories</th>
                                 <th>Tags</th>
                                 <th>Status</th>
                                 <th>Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              {dataListArray.length > 0 ? <>{prepareHtmlTable()}</> : <><tr className="text-center"><td colSpan="6">No data available</td></tr></>}
                           </tbody>
                        </table>
                        <div className='pag'>
                           <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                              pageSize={paginationData.perpage} onPageChange={page => setPaginationData({ ...paginationData, page: page })}
                           />
                        </div>
                     </div>
                  }
               </div>
            </div>
         </div>
      </div>
   )

}
