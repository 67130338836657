import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { contactUsList, deleteContactUs } from '../../apis/DirectUsObject';
import { toast } from 'react-toastify';
import Alerts from '../../common/Alerts/Alerts';
import Pagination from '../Pagination/pagination';
import STRINGS from '../../common/strings/strings';
import { CheckUnAuthorized } from '../Common/CheckUnAuthorized';
import { useNavigate } from 'react-router-dom';
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConfig from '../../configs/AppConfig';
import appRoutes from '../../configs/AppRoutes';
import { ContentLoading } from '../Common/ContentLoading';

export default function ContatUs() {
	const { loading, alert } = useSelector((state) => state.common);
	const [dataList, setDataList] = useState([]);
	const [paginationData, setPaginationData] = useState({ perpage: AppConfig.recordsPerPageTable, page: 1, totalCount: 0 });
	const [filter, setFilter] = useState({ searchKeyword: '' })
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		getContactUsList()
	}, [paginationData.page]);

	const getContactUsList = async () => {
		dispatch(callCommonAction({ loading: true }));
		await contactUsList(filter, paginationData).then(res => {
			setDataList(res.data)
			setPaginationData({ ...paginationData, totalCount: res.meta.filter_count });
			toast.success(STRINGS.usersListSuccess)
			dispatch(callCommonAction({ loading: false }));
		}).catch(error => {
			CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
		});
	}


	const confirmBeforeDelete = (selected_id) => {
		//console.log('selected_id', selected_id);
		const custom = Alerts.confirmDelete(selected_id, deleteSelectedContact);
		dispatch(callCommonAction({ alert: custom }));
	}

   //after confirmation delete data
	const deleteSelectedContact = async (selected_id) => {
		dispatch(callCommonAction({ alert: null }));
		if (selected_id) {
			dispatch(callCommonAction({ loading: true}));
				await deleteContactUs(selected_id).then(async (res) => {
				await getContactUsList();
				dispatch(callCommonAction({ loading: false }));
			}).catch((error) => {
				CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
			});
		}
	};

	const prepareHtmlTable = () => {
      return dataList.map((item) => {
         return (
            <tr v-for="item in tableItems" key={item.id}>
				<td>{item.name}</td>
				<td>{item.email}</td>
				<td>{item.message}</td>
				<td><span className={`badge ${item.status == '1' ? 'bg-success' : 'bg-danger'}`}>{item.status == "1" ? 'Active' : 'In-Active'}</span></td>
				<td>
					<ul className="list-unstyled d-flex action-icons m-0">
						<li><button className="btn btn-sm" type="button" onClick={() => confirmBeforeDelete(item.id)}><FontAwesomeIcon icon={faTrashCan} className='text-danger' /></button></li>
					</ul>
				</td>
            </tr>
         )
      })
	}

	return (
      <div className="content-wrapper">
         <div className="container">
            <div className="d-flex justify-content-between align-items-center breadcrum-container mb-3">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <a href={undefined} onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.dashboardRoute)}>Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Conatct Us List</li>
               </ol>
               
            </div>
            {alert}
            <div className="card card-custom shadow mb-3">
               <div className="card-header">
                  <div className="card-title">
                     <i className="fa fa-table"></i> Conatct Us List
                  </div>
                  <div className="card-toolbar">
                     <div className="d-flex align-items-center flex-wrap mb-0">
                        <div className="dash-search">
                           <form className="form-inline my-2 my-lg-0 mr-lg-2">
                              <div className="input-group">
                                 <input className="form-control border-end-0 border rounded-pill" onChange={(e) => setFilter({ ...filter, searchKeyword: e.target.value })} value={filter.searchKeyword} type="text" placeholder="Search for..." />
                                 <span className="input-group-append">
                                    <button className="btn border-0 rounded-pill ms-n5" type="button" onClick={() => getContactUsList()}><i className="fa fa-search text-black"></i></button>
                                 </span>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="card-body spinner-relative">
                  {loading ? <ContentLoading /> :
                     <div className="table-responsive">
                        <table className="table table-bordered" width="100%" cellSpacing="0">
                           <thead>
                              <tr>
                                 <th>Name</th>
                                 <th>Email</th>
                                 <th>Message</th>
                                 <th>Status</th>
                                 <th>Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              {dataList.length > 0 ? <>{prepareHtmlTable()}</> : <><tr className="text-center"><td colSpan="6">No data available</td></tr></>}
                           </tbody>
                        </table>
                        <div className='pag'>
                           <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                              pageSize={paginationData.perpage} onPageChange={page => setPaginationData({ ...paginationData, page: page })}
                           />
                        </div>
                     </div>
                  }
               </div>
            </div>
         </div>
      </div>
   )

}
