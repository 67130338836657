import STRINGS from '../../../common/strings/strings';
import Helper from '../../../apis/Helper';

export function validateContactFormData(data,fieldName) {
	let error = {};
	if(data.name === "")
		error.name = STRINGS.contact.nameReq;
	if(data.email === "")
		error.email = STRINGS.contact.emailReq;
	if(data.message === "")
		error.message = STRINGS.contact.messageReq;
	
	return error
}