import React, { Suspense, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import appRoutes from './configs/AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { callCommonAction } from './redux/Common/CommonReducer';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Home from './components/Home/Home';
import NoRouteFound from './components/NoRouteFound';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Users from './components/User/UserList/UserList';
import AddUser from './components/User/AddUser';
import AddBlog from './components/Blog/AddBlog';
import BlogList from './components/Blog/BlogList';
import ContatUs from './components/Pages/ContatUs';
import AboutUs from './components/Pages/AboutUs';
import FAQ from './components/Pages/FAQ';
import TermsCondition from './components/Pages/TermsCondition';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const AuthLayout = React.lazy(() => import('./layout/AuthLayout'));

function App() {
	const dispatch = useDispatch();
	const storedValueInLocalStorage = { ...localStorage };
	useEffect(() => {
		if (storedValueInLocalStorage.token != 'undefined' && storedValueInLocalStorage.token !== null && storedValueInLocalStorage.hasOwnProperty('token')) {
			dispatch(callCommonAction({ isLoggedIn: true, token: storedValueInLocalStorage.token, user: {} }));
		}
	});

	return (
		<div className="App">
			<ToastContainer autoClose={1500} />
			<BrowserRouter>
				<Suspense>
					<Routes>
						<Route exact path="/auth" element={<AuthLayout />} >
							<Route exact path={appRoutes.dashboardRoute} element={<Dashboard />} />
							<Route exact path={appRoutes.userListRoute} element={<Users />} />
							<Route exact path={appRoutes.addEditUserRoute + '/:user_id?'} element={<AddUser />} />
							<Route exact path={appRoutes.addEditBlogRoute + '/:blog_id?'} element={<AddBlog />} />
							<Route exact path={appRoutes.blogListRoute} element={<BlogList />} />
							<Route exact path={appRoutes.contactUsRoute} element={<ContatUs />} />
						</Route>
						<Route exact path="/" element={<DefaultLayout />} >
							<Route exact index element={<Home />} />
							<Route exact path={appRoutes.loginRoute} element={<Login />} />
							<Route exact path={appRoutes.forgotPasswordRoute} element={<ForgotPassword />} />
							<Route exact path={appRoutes.resetPasswordRoute} element={<ResetPassword />} />
							<Route exact path={appRoutes.aboutUsRoute} element={<AboutUs />} />
							<Route exact path={appRoutes.faqRoute} element={<FAQ />} />
							<Route exact path={appRoutes.termsConditionRoute} element={<TermsCondition />} />
							<Route exact path={appRoutes.privacyPolicyRoute} element={<PrivacyPolicy />} />
						</Route>
						<Route path='*' element={<NoRouteFound />} />
					</Routes>
				</Suspense>
			</BrowserRouter>
		</div>
	);
}

export default App;
