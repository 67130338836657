import axios from 'axios';
import appSettings from '../configs/AppConfig';
export default class Helper {

    //Validate email address
    static validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    //Validate password
    static validatePassword = (password) => {
        return (password && password.length >= 6) ? true : false;
    }

}