import { Directus } from '@directus/sdk';
import AppConfig from '../configs/AppConfig';
import axios from 'axios';
import { store } from '../app/store';

const API_ENDPOINT = process.env.REACT_APP_CMS_URL_STAGING;
const directus = new Directus(API_ENDPOINT);

export function login(userDetail) {
	return directus.auth.login(userDetail);
}

export function forgotPassword(email) {
	const reset_url = process.env.REACT_APP_RESET_PASSWORD_LOCAL == 'undefined' ? process.env.REACT_APP_RESET_PASSWORD_LOCAL : 'http://localhost:3000/reset-password';
	//console.log('reset_url', reset_url)
	return directus.auth.password.request(email, reset_url);
}

export function resetPassword(userDetail) {
	return directus.auth.password.reset(userDetail.token, userDetail.password);
}

export function getUserDetail(userId) {
	return directus.items('directus_users').readOne(userId);
}

export function createUser(user_id, userDetail) {
	if (user_id !== '0') {
		return directus.items('directus_users').updateOne(user_id, userDetail);
	} else {
		return directus.items('directus_users').createOne(userDetail);
	}
}



//Get List Of Caletgories
export function getListOfCategories() {
	return directus.items('categories').readByQuery({ limit: -1 });
}


export function uploadUserProfileImage(formData) {
	const usertoken = store.getState().common.token;
	const authHeaders = { Authorization: `Bearer ` + usertoken, Accept: 'application/json' };
	return axios({ method: 'POST', url: API_ENDPOINT + '/files', data: formData, headers: authHeaders });
}

export function logout() {
	return directus.auth.logout();
}

export function deleteImage(imageId) {
	return directus.items('directus_files').deleteOne(imageId);
}

export function usersList(filter, page) {
	return directus.items('directus_users').readByQuery({ search: filter.searchKeyword, meta: '*', limit: AppConfig.recordsPerPageTable, page: page });
}

export function deleteUser(userId) {
	return directus.items('directus_users').deleteOne(userId);
}

/*************** Blog List *******/
export function blogList(filter, page) {
	return directus.items('blogs').readByQuery({ fields : '*.categories_id.*,user_created.*', search: filter.searchKeyword, meta: '*', limit: AppConfig.recordsPerPageTable, page: page });
}

/****************Create And Update Blog*****************/
export function createAndUpdateBlog(blog_id, blogDetail) {
	if (blog_id !== undefined) {
		return directus.items('blogs').updateOne(blog_id, blogDetail);
	} else {
		return directus.items('blogs').createOne(blogDetail);
	}
}

export function saveBlogCategory(data) {
	return directus.items('blog_categories').createMany(data);
}

/** Get Specific Blog detail */
export function getAddEditBlog(blog_id) {
	return directus.items('blogs').readOne(blog_id);
}

/** Delete blog fuctioality here  */
export function deleteBlog(userId) {
	return directus.items('blogs').deleteOne(userId);
}

//Contact-Us Module
export function saveContactUs(data) {
	return directus.items('contact_us').createOne(data);
}

export function contactUsList(filter, paginate) {
	return directus.items('contact_us').readByQuery({ meta: '*', search: filter.searchKeyword, limit: AppConfig.recordsPerPageTable, page: paginate.page });
}

export function deleteContactUs(contactId) {
	return directus.items('contact_us').deleteOne(contactId);
}

//Get List Of Reviews
export function getReviews() {
	return directus.items('reviews').readByQuery({ limit: 3 });
}