import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { getAddEditBlog, getListOfCategories, uploadUserProfileImage, saveBlogCategory, createAndUpdateBlog } from '../../apis/DirectUsObject';
import { toast } from 'react-toastify';
import { CheckUnAuthorized } from '../Common/CheckUnAuthorized';
import { useParams, useNavigate } from 'react-router-dom';
import { faUpload, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConfig from '../../configs/AppConfig';
import appRoutes from '../../configs/AppRoutes';
import { ContentLoading } from '../Common/ContentLoading';
import { validateBlogFormData } from './AddEditValidation'
import Select from 'react-select';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import STRINGS from '../../common/strings/strings';

export default function AddUser() {
	const { loading, alert, user } = useSelector((state) => state.common);
	const [blogDetail, setBlogDetail] = useState({ title: '', slug: '', description: '', display_image: null, banner_image: null, categories: [] });
	const [selectedImage, setSelectedImage] = useState(null);
	const [errorsInfo, setErrorsInfo] = useState({});
	const [categoryList, setCategoryList] = useState([]);
	const [selectedCategoryOption, setSelectedCategoryOption] = useState([]);
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let { blog_id } = useParams();

	useEffect(() => {
		getCategories();
	}, []);

	/**Get the lis of categories***/
	const getCategories = async () => {
		const res = await getListOfCategories();
		const categories = res.data.map((item) => {
			return { value: item.id, label: item.name }
		})
		if (blog_id != undefined) {
			getBlog(categories);
		}
		setCategoryList(categories);
	}

	const onEditorStateChange = (content) => { setEditorState(content); }

	//** autoPopulateCat funtion  */
	const autoPopulateCat = (selCatOp, catLi) => {
		if (selCatOp.length && catLi.length)
			return selCatOp.map((item) => (catLi.findIndex(i => i.value === item.value) != '-1') ? item : null);
		return null
	}

	const getBlog = async (categories) => {
		let res = await getAddEditBlog(blog_id);
		setBlogDetail({ ...blogDetail, description: res.description, categories: res.categories, title: res.title, slug: res.slug, display_image: res.display_image });
		console.log('res.categories',res.categories)
		console.log('categories',categories)
		const seletedCat = categories.filter((item) => {
			return res.categories.includes(item.value) ? true : false;
		})
		const initaleditorState = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res.description)))
		setEditorState(initaleditorState)
		console.log('seletedCat', seletedCat)
		setSelectedCategoryOption(seletedCat)
		dispatch(callCommonAction({ loading: false }));

	}

	/** OnChange Update Input Values */
	const handleChange = (e) => {
		setBlogDetail({ ...blogDetail, [e.target.name]: e.target.value });
		checkFormIsValid(e.target.name)
	}
	/** Validation implemented here */
	const checkFormIsValid = (fieldName) => {
		let errors = validateBlogFormData(blogDetail, fieldName);
		console.log("errors", errors)
		if (Object.keys(errors).length > 0) {
			setErrorsInfo(errors);
			return false;
		} else {
			setErrorsInfo({});
			return true;
		}
	}

	/** Form Submit Functionality Here */
	const submitBlogForm = async (e) => {
		e.preventDefault();
		if (checkFormIsValid('')) {
			dispatch(callCommonAction({ loading: true }));
			const blogInfo = blogDetail
			if (selectedImage) {
				await callImageUpload(blogInfo);
			} else {
				await callSaveBlog(blogInfo);
			}
		}
	}

	const callImageUpload = async (blogInfo) => {
		const formData = new FormData();
		formData.append('title', blogInfo.title);
		formData.append('file', selectedImage);

		await uploadUserProfileImage(formData)
			.then(async (res) => {
				const image_id = res.data.data.id;
				blogInfo.display_image = image_id;
				blogInfo.banner_image = image_id;
				console.log('file name', image_id)
				await callSaveBlog(blogInfo)
			}).catch(error => {
				CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
			});
	}

	/** Call Save Blog With Image Or Without Image During Update */
	const callSaveBlog = async (blogInfo) => {
		let content_html = convertToHTML(editorState.getCurrentContent());
		blogInfo.description = content_html;
		const newCategories = selectedCategoryOption.map((item) => item.value);
		console.log('new cat',newCategories)
		blogInfo.categories = newCategories;
		blogInfo.status = 'published';
		await createAndUpdateBlog(blog_id, blogInfo)
			.then(async (res) => {
				dispatch(callCommonAction({ loading: false }));
				console.log('create blog res', res)
				var message = blog_id !== '0' ? STRINGS.blogEditSuccess : STRINGS.blogAddSuccess;
				toast.success(message)
				navigate(appRoutes.authPrfixRoute + '/' + appRoutes.blogListRoute)
			})
			.catch((error) => {
				dispatch(callCommonAction({ loading: false }));
				console.log('create blog catch error', error)
			});
	}

	const removeImage = async (e) => {
		if (blogDetail.display_image === null) {
			setSelectedImage(null)
		} else {
			// await deleteImage(blogDetail.display_image).then(res => {
			// 	setSelectedImage(null)
			// 	setBlogDetail({ ...blogDetail, display_image: null });
			// }).catch(error => {
			// 	CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
			// });
		}
	}
	return (
		<div className="content-wrapper">
			<div className="container">
				<div className="d-flex justify-content-between align-items-center breadcrum-container mb-3">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<a href={undefined} onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.dashboardRoute)}>Dashboard</a>
						</li>
						<li className="breadcrumb-item active">Add Blog</li>
					</ol>
				</div>
				{alert}
				<div className="card card-custom shadow mb-3">
					<div className="card-body spinner-relative">
						{loading ? <ContentLoading /> :
							<div className="form">
								<form id="login-form" noValidate onSubmit={submitBlogForm}>
									<div className='text-center mb-4 '>
										{selectedImage != null ?
											(
												<img alt="not found" height={160} width={160} src={URL.createObjectURL(selectedImage)} />
											)
											:
											blogDetail.display_image != null ?
												<img src={AppConfig.base_url + '/assets/' + blogDetail.display_image} height={160} width={160} alt='Profile' title='Profile' />
												:
												<img src='/assets/images/profile-dummy.png' height={160} width={160} alt='Profile' title='Profile' />
										}
									</div>
									<div className="d-flex justify-content-center mb-4 mt-2">
										<div className='d-flex align-items-center cstm-upload-btn'>
											{/* {selectedImage != null && blogDetail.display_image == null ?
												<button type="button" className="btn btn-primary btn-sm" onClick={() => saveUserSelectedImage()}>
													<FontAwesomeIcon icon={faUpload} className="me-1" />
													Upload
												</button>
												: null
											} */}
											<input type="file" name="image" onChange={(event) => setSelectedImage(event.target.files[0])} />
										</div>
										{selectedImage != null || blogDetail.display_image != null ?
											<button type="button" onClick={(e) => removeImage(e)} className="btn btn-outline-danger ms-1 btn-sm"><FontAwesomeIcon icon={faRemove} className="me-1" />Remove</button>
											:
											null
										}
									</div>
									<div className="form-body">
										<div className="form-group">
											<label className="form-control-label">Title</label>
											<input type="text" name="title" className="form-control" onChange={handleChange} placeholder="Enter Title" value={blogDetail.title} />
											<span className='invalid-field'>{errorsInfo.title}</span>
										</div>
										<div className="form-group">
											<label className="form-control-label" htmlFor="categories">Category </label>
											<Select name="categories" value={autoPopulateCat(selectedCategoryOption, categoryList)} isSearchable isMulti onChange={setSelectedCategoryOption} options={categoryList} />
											<span className='invalid-field'>{errorsInfo.categories}</span>
										</div>
										<div className="form-group">
											<label className="form-control-label" htmlFor="description">Description </label>
											<Editor className='text-body' editorState={editorState} wrapperClassName="wrapper-class" editorClassName="editor-class" toolbarClassName="toolbar-class" onEditorStateChange={onEditorStateChange} />
											<span className='invalid-field'>{errorsInfo.description}</span>
										</div>
									</div>
									<div className="border-top mb-5 mt-5 pt-3">
										{loading ?
											<button type="button" className="btn btn-primary">Save <span className="spinner-border" role="status"></span></button>
											:
											<button type="submit" className="btn btn-primary">Save</button>
										}
										<button className="btn btn-sm btn-secondary mt-2" onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.blogListRoute)}>Cancel</button>
									</div>
								</form>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)

}
