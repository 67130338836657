const STRINGS = {
    login: 'login',
    loginSuccess: 'You are logged in successfully.',
    invalidEmail : 'The email must be a valid email address.',
    emailReq : 'Please enter email address',
    invalidPassword : 'Password must be at least 8 characters in length.',
    passwordReq : 'Password field is required.',
    invalidConfirmPassword : 'Confirm password must be at least 8 characters in length.',
    confirmPasswordReq :  'Confirm password field is required.',
    passwordMissMatch :  'The password input and confirm password must match.',
    FORGET_PASSWORD_EMAIL_SENT:'Email reset link is sent to your email. Please check it.',
    PASSWORD_RESET_SUCCESS:'Your password reset successfully. Please login',
	usersListSuccess: 'User list get successfully.',
    usersAddSuccess: 'User added successfully.',
    usersEditSuccess: 'User updated successfully.',
	users : {
        firstNameReq : 'First Name field is required.',
        lastNameReq : 'Last Name field is required.',
        titleReq : 'Title field is required.',
        descriptionReq: 'Description field is required.',
        emailReq : 'Email field is required.',
        locationReq : 'Location field is required.',
    },
    blogs : {
        titleReq : 'Title field is required.',
        descriptionReq: 'Description field is required.',
    },
	blogListSuccess: 'Blog list get successfully.',
    blogAddSuccess: 'Blog added successfully.',
    blogEditSuccess: 'Blog updated successfully.',
	blogDeleteSuccess: 'Blog deleted successfully.',
	contact : {
        nameReq : 'Name field is required.',
        emailReq: 'Email field is required.',
        messageReq: 'Message field is required.',
    },
	contactSuccess: 'Your message saved successfully. Someone from team will contact you soon',
}
export default STRINGS;