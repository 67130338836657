import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { getUserDetail, uploadUserProfileImage, deleteImage, createUser } from '../../apis/DirectUsObject';
import { toast } from 'react-toastify';
import Alerts from '../../common/Alerts/Alerts';
import STRINGS from '../../common/strings/strings';
import { CheckUnAuthorized } from '../Common/CheckUnAuthorized';
import { useParams, useNavigate } from 'react-router-dom';
import { faUpload, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConfig from '../../configs/AppConfig';
import appRoutes from '../../configs/AppRoutes';
import { ContentLoading } from '../Common/ContentLoading';
import { validateUserFormData } from './Validation/AddEditValidation'

export default function AddUser() {
	const { loading, alert, user } = useSelector((state) => state.common);
	const [userDetail, setUserDetail] = useState({ first_name: '', last_name: '', email: '', location: '', title: '', description: '', avatar: null });
	const [selectedImage, setSelectedImage] = useState(null);
	const [errorsInfo, setErrorsInfo] = useState({});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user_id } = useParams();

	useEffect(() => {
		if (user_id !== '0') {
			getUsersData()
		}
	}, []);

	const getUsersData = async () => {
		dispatch(callCommonAction({ loading: true }));
		await getUserDetail(user_id).then(res => {
			//console.log('get user detail response', res)
			setUserDetail({ ...userDetail, first_name: res.first_name, last_name: res.last_name, email: res.email, location: res.location, title: res.title, description: res.description, avatar: res.avatar });
			dispatch(callCommonAction({ loading: false }));
		}).catch(error => {
			CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
		});
	}

	/** OnChange Update Input Values */
	const handleChange = (e) => {
		//console.log('after load handle change function called');
		setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
	}
	/** Validation implemented here */
	const checkFormIsValid = () => {
		let errors = validateUserFormData(userDetail, '');
		console.log("errors", errors)
		if (Object.keys(errors).length > 0) {
			setErrorsInfo(errors);
			return false;
		} else {
			return true;
		}
	}

	const callImageUpload = async (userInfo) => {
		const formData = new FormData();
		formData.append('title', 'User Profile Image');
		formData.append('file', selectedImage);
		await uploadUserProfileImage(formData).then(async(res) => {
			const image_id = res.data.data.id;
			userInfo.avatar = image_id
			setUserDetail({ ...userDetail, avatar: image_id });
			await callSaveUser(userInfo)
		}).catch(error => {
			CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
		});
	}

	/** Form Submit Functionality Here */
	const submitUserForm = async (e) => {
		e.preventDefault();
		if (checkFormIsValid('')) {
			dispatch(callCommonAction({ loading: true }));
			const userInfo = userDetail
			if (selectedImage) {
				await callImageUpload(userInfo);
			} else {
				await callSaveUser(userInfo);
			}
		}
	}

	/** Form Submit Functionality Here */
	const callSaveUser = async (userInfo) => {
		await createUser(user_id, userInfo)
			.then((res) => {
				dispatch(callCommonAction({ loading: false }));
				console.log('create user res', res)
				var message = user_id !== '0' ? STRINGS.usersEditSuccess : STRINGS.usersAddSuccess;
				toast.success(message)
				navigate(appRoutes.authPrfixRoute + '/' + appRoutes.userListRoute)
			})
			.catch((error) => {
				dispatch(callCommonAction({ loading: false }));
				console.log('create user catch error', error)
			});
	}

	const removeImage = async (e) => {
		if (userDetail.avatar === null) {
			setSelectedImage(null)
		} else {
			await deleteImage(userDetail.avatar).then(res => {
				setSelectedImage(null)
				setUserDetail({ ...userDetail, avatar: null });
			}).catch(error => {
				CheckUnAuthorized({ error: error, dispatch: dispatch, navigate: navigate, callCommonAction: callCommonAction })
			});
		}
	}

	//console.log('userDetail', userDetail)
	return (
		<div className="content-wrapper">
			<div className="container">
				<div className="d-flex justify-content-between align-items-center breadcrum-container mb-3">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<a href={undefined} onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.dashboardRoute)}>Dashboard</a>
						</li>
						<li className="breadcrumb-item active">Add User</li>
					</ol>
				</div>
				{alert}
				<div className="card card-custom shadow mb-3">
					<div className="card-body spinner-relative">
						{loading ? <ContentLoading /> :
							<div className="form">
								<form id="login-form" noValidate onSubmit={submitUserForm}>
									<div className='text-center mb-4 '>
										{selectedImage != null ?
											(
												<img alt="not found" height={160} width={160} src={URL.createObjectURL(selectedImage)} />
											)
											:
											userDetail.avatar != null ?
												<img src={AppConfig.base_url + '/assets/' + userDetail.avatar} height={160} width={160} alt='Profile' title='Profile' />
												:
												<img src='/assets/images/profile-dummy.png' height={160} width={160} alt='Profile' title='Profile' />
										}
									</div>
									<div className="d-flex justify-content-center mb-4 mt-2">
										<div className='d-flex align-items-center cstm-upload-btn'>
											{/* {selectedImage != null && userDetail.avatar == null ?
												<button type="button" className="btn btn-primary btn-sm" onClick={() => saveUserSelectedImage()}>
													<FontAwesomeIcon icon={faUpload} className="me-1" />
													Upload
												</button>
												: null
											}	 */}
											<input type="file" name="image" onChange={(event) => setSelectedImage(event.target.files[0])} />
										</div>
										{selectedImage != null || userDetail.avatar != null ?
											<button type="button" onClick={(e) => removeImage(e)} className="btn btn-outline-danger ms-1 btn-sm"><FontAwesomeIcon icon={faRemove} className="me-1" />Remove</button>
											:
											null
										}
									</div>
									<div className="form-body">
										<div className="form-group">
											<label className="form-control-label" htmlFor="first_name">First Name</label>
											<input className="form-control" type="text" onChange={handleChange} value={userDetail.first_name} name="first_name" placeholder="Enter First Name" />
											<span className='invalid-field'>{errorsInfo.first_name}</span>
										</div>
										<div className="form-group">
											<label className="form-control-label" htmlFor="last_name">Last Name</label>
											<input className="form-control" type="text" onChange={handleChange} value={userDetail.last_name} name="last_name" placeholder="Enter Last Name" />
											<span className='invalid-field'>{errorsInfo.last_name}</span>
										</div>

										<div className="form-group">
											<label className="form-control-label">Email</label>
											<input type="email" name="email" className="form-control" onChange={handleChange} placeholder="Enter Email" value={userDetail.email} />
											<span className='invalid-field'>{errorsInfo.email}</span>
										</div>
										<div className="form-group">
											<label className="form-control-label">Location</label>
											<input type="text" name="location" className="form-control" onChange={handleChange} placeholder="Enter Location" value={userDetail.location} />
											<span className='invalid-field'>{errorsInfo.location}</span>
										</div>
										<div className="form-group">
											<label className="form-control-label">Title</label>
											<input type="text" name="title" className="form-control" onChange={handleChange} placeholder="Enter Title" value={userDetail.title} />
											<span className='invalid-field'>{errorsInfo.title}</span>
										</div>
										<div className="form-group">
											<label className="form-control-label">Description</label>
											<input type="text" name="description" className="form-control" onChange={handleChange} placeholder="Enter Description" value={userDetail.description} />
											<span className='invalid-field'>{errorsInfo.description}</span>
										</div>
									</div>
									<div className="border-top mb-5 mt-5 pt-3">
										{loading ?
											<button type="button" className="btn btn-primary">Save <span className="spinner-border" role="status"></span></button>
											:
											<button type="submit" className="btn btn-primary">Save</button>
										}
										<button className="btn btn-sm btn-secondary mt-2" onClick={() => navigate(appRoutes.authPrfixRoute + '/' + appRoutes.userListRoute)}>Cancel</button>
									</div>
								</form>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)

}
