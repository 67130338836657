import STRINGS from '../../common/strings/strings';

export function validateResetPasswordFormData(userDetail) {
    const { password } = userDetail;
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    if (password == '') {
		errorMsg = STRINGS.passwordReq;
		formVaildCheck = false;
		errors.password = errorMsg;
    }
    return { formVaildCheck:formVaildCheck, errors:errors};
}