import STRINGS from '../../common/strings/strings';

export function validateLoginFormData(userDetail,fieldName) {
    const { email, password } = userDetail;
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
	
	var regEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
    if ((fieldName == 'email' || fieldName == '') && (!regEx.test(email))) {
      errorMsg = email == '' ? STRINGS.emailReq : STRINGS.invalidEmail;
      formVaildCheck = false;
      errors.email = errorMsg;
    }
    if ((fieldName == 'password' || fieldName == '') && (password.length < 6)) {
      errorMsg = password == '' ? STRINGS.passwordReq : STRINGS.invalidPassword;
      errors.password = errorMsg;
      formVaildCheck = false;
    }
    return { formVaildCheck:formVaildCheck, errors:errors};
}