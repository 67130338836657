import STRINGS from '../../common/strings/strings';

export function validateForgotPasswordFormData(userDetail) {
    const { email } = userDetail;
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
	
	var regEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEx.test(email)) {
      errorMsg = email == '' ? STRINGS.emailReq : STRINGS.invalidEmail;
      formVaildCheck = false;
      errors.email = errorMsg;
    }
    return { formVaildCheck:formVaildCheck, errors:errors};
}