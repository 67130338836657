import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation  } from "react-router-dom";
import { toast } from 'react-toastify';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import STRINGS from '../../common/strings/strings';
import appRoutes from '../../configs/AppRoutes';
import { useNavigate } from 'react-router-dom';
import { validateResetPasswordFormData } from './Validation';
import { resetPassword } from '../../apis/DirectUsObject';
import appSettings from '../../configs/AppConfig';

export default function ResetPassword() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const [userDetail, setUserDetail] = useState({ password: '', token : token });
  const loading = useSelector((state) => state.common.loading)
  const dispatch = useDispatch();
  const [errorsInfo, setErrorsInfo] = useState({});

  /** Forgot Password Form OnChange */
  const handleChange = (e) => {
    userDetail[e.target.name] = e.target.value;
    setUserDetail(userDetail);
    checkFormIsValid()
  }

  /** Validation implemented here */
  const checkFormIsValid = () => {
    const res = validateResetPasswordFormData(userDetail);
    setErrorsInfo(res.errors);
    return res.formVaildCheck;
  }

  /** Forgot Password Form Submit */
  const resetPasswordUser = async (e) => {
    e.preventDefault();
    if (checkFormIsValid(userDetail)) {
		dispatch(callCommonAction({ loading: true }));
		await resetPassword(userDetail)
			.then(res => {
				//console.log("Success ========>", res);
				toast.success(STRINGS.PASSWORD_RESET_SUCCESS)
				navigate(appRoutes.loginRoute);
			})
			.catch(error => {
				toast.error(error.errors[0].message)
			});
		dispatch(callCommonAction({ loading: false }));
    }
  }

  return (
    <>
      <section className="inner-page">
        <div className="container">
          <div className="form-wrap">
            <div className="col-12 form-group">
              <h2 className="h4 text-white mb-4">Set New password</h2>
            </div>
            <div className="form-content login-content" style={{marginTop: '100px'}}>
              <form className="form" noValidate onSubmit={resetPasswordUser}>
                <div className="col-12 form-group">
                  <label>Password</label>
                  <input className="form-control" type="password" name="password" id="password" onChange={handleChange} defaultValue={userDetail.password} placeholder="Enter new password" />
                  <span className='invalid-field'>{errorsInfo.password}</span>
                </div>
                {loading ? 
                  <button type="button" className="btn btn-primary w-100">Saving <span className="spinner-border" role="status"></span></button>
                  :
                  <button type="submit" className="btn btn-primary w-100">Save</button>
                }
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
